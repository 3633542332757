import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ConsoleAPI from '../../api/ConsoleAPI';
import { SubmitButton } from '../../components/Button';
import { Container } from '../../components/Container';
import { Loader } from '../../components/Loader';
import { TextInput } from '../../components/TextInput';
import { Title } from '../../components/Title';

function ReportsPage() {
    const [isLoading, setIsLoading] = useState(true);
    const [reportTypes, setReportTypes] = useState([]);
    const [files, setFiles] = useState([]);
    const [fromDate, setFromDate] = useState(moment().subtract(10, 'day').format("YYYY-MM-DD"));
    const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
    const [folder, setFolder] = useState('');

    useEffect(() => {
        loadReportTypes();
    }, []);

    const loadReportTypes = () => {
        setIsLoading(true);
        ConsoleAPI.getReportTypes((data) => {
            setReportTypes(data.item);
            setIsLoading(false);
        });
    }

    const loadFiles = (type) => {
        if (type) {
            setFolder(type);
            setIsLoading(true);
            ConsoleAPI.getReportFiles(type, fromDate, toDate, (data) => {
                setFiles(data.item);
                console.log(data);
                setIsLoading(false);
            });
        }
    }


    const loadFile = (id) => {
        ConsoleAPI.getReportFile(id, (data) => {
            console.log(data);
            window.open(data.item.downloadURL);
        });
    }

    const refreshFiles = () => {
        loadFiles(folder);
    }

    return (
        <div className="container-fluid">
            <div className="row row-eq-height">
                <div className="col-sm-12 content">
                    <Title title="Reports" />
                    <Container>
                        <div className="row">
                            <div className="col-sm-3 offset-sm-2">
                                <TextInput
                                    type="date"
                                    name="fromDate"
                                    placeholder="From"
                                    label="Period From"
                                    value={fromDate}
                                    onChange={(name, value) => setFromDate(value)}
                                />
                            </div>
                            <div className="col-sm-3">
                                <TextInput
                                    type="date"
                                    name="toDate"
                                    placeholder="To"
                                    label="Period To"
                                    value={toDate}
                                    onChange={(name, value) => setToDate(value)}
                                />
                            </div>
                            <div className="col-sm-2">
                                <div className="form-group">
                                    <label>&nbsp;</label>
                                    <div className="input-group" style={{ paddingTop: 10 }}>
                                        <SubmitButton
                                            text="Filter"
                                            className="btn btn-full"
                                            full={true}
                                            onClick={refreshFiles}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        {isLoading ? <Loader /> : (
                            <div>
                                {reportTypes.map(item => (
                                    <React.Fragment key={item.srtType}>
                                        <h3 style={{ cursor: 'pointer' }} onClick={() => loadFiles(item.srtType)}>
                                            {item.srtType === folder ? '-' : '+'}&nbsp;{item.srtDescription}
                                        </h3>
                                        {item.srtType === folder && (
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th width="20%">Date</th>
                                                        <th width="70%">Filename</th>
                                                        <th width="10%">&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {files.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="3" className="text-center">No reports found.</td>
                                                        </tr>
                                                    ) : (
                                                        files.map((file, index) => (
                                                            <tr key={index}>
                                                                <td>{moment(file.createdAt).format("DD MMM YYYY HH:mm")}</td>
                                                                <td>
                                                                    <div style={{ cursor: 'pointer', color: '#007ace' }} onClick={() => loadFile(file.logId)}>
                                                                        {file.fileName}
                                                                    </div>
                                                                </td>
                                                                <td>&nbsp;</td>
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>
                                            </table>
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>
                        )}
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default ReportsPage;
